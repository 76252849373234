@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

:deep(.q-page.mobile) {
    min-height: 100px !important;
}

.carousel {
    &__title {
        @media screen and (max-width: $breakpoint-mobile) {
            font: {
                size: 32px;
                weight: 700;
            }
            line-height: 44px;
        }
    }
}

.carousel__title {
    @media (max-width: 1280px) {
        font-size: 1rem;
    }
}

.ceo {
    &__text {
        width: 747px;

        @media screen and (max-width: 1280px) {
            width: 485px;
        }
    }

    &__img {
        height: 298px;
        margin-right: 8px;

        @media screen and (max-width: 1440px) {
            height: 278px;
        }
        @media screen and (max-width: 1280px) {
            height: 240px;
            margin-top: 48px;
        }
    }
}

.title-top {
    max-width: 234px;
}

.mw-1 {
    max-width: 227px;
}

.additional {
    background: #E2F0E8;
    border-radius: 16px;
    overflow: hidden;
    height: 184px;
}


